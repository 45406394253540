import Button from 'primevue/button'
import Card from 'primevue/card'
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { idb } from '@/data/source/local/databases/indexeddb/idb'
import {
  getListAgent,
  openTicketUseCase,
  courierUseCase,
  authUseCase
} from '@/domain/usecase'
import moment from 'moment'
import { isMobile } from '@/plugins/system/device'

import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive
} from 'vue'
import iconDependency from '@/dependencies/iconDependency'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers,
  minLength
} from '@vuelidate/validators'
import {
  environment
} from '@/utils'

const modules = 'deliveryItem'

export default {
  name: 'Login',
  components: {
    Password,
    InputText,
    Button,
    Card
  },
  setup() {
    const version = environment.getVersion()
    const store = useStore()
    const result = ref('My result in here yahh')
    const email = ref('')
    const password = ref('')
    const dataForm = reactive({
      email: '',
      password: '' as any
    })
    const submitted = ref(false)
    const router = useRouter()
    const route = useRoute()
    const app = getCurrentInstance()
    const loading = ref(false)
    const {
      $toast, $strInd
    } = app!.appContext.config.globalProperties
    const icons = ref(iconDependency()) as any
    const baseUrl = `${process.env.BASE_URL}`
    const checkResponse = (data: any) => {
      $toast.add({
        severity: 'error',
        detail: data.message,
        group: 'bc',
        life: 3000
      })
    }
    const rules = {
      password: {
        required: helpers.withMessage('Password harus diisi', required),
        minLength: helpers.withMessage('Password minimal mengandung 6 karakter.', minLength(6))
      },
      email: {
        required: helpers.withMessage('Email harus diisi', required)
      }
    }
    const v$ = useVuelidate(rules, dataForm)
    const getDataGlobalSetting = async (val: any) => {
      const response = await authUseCase.getGlobalSetting(Number(val))
      return response.result
    }

    // eslint-disable-next-line arrow-body-style
    const mappingCourier = (value: any) => value.map((val: any) => {
      return {
        BirthDate: val.BirthDate,
        Handphone: val.Handphone,
        Id: val.Id,
        Nama: val.Nama,
        Photo: val.Photo,
        TempatLahir: val.TempatLahir,
        JenisKelamin: val.JenisKelamin,
        IdKendaraan: val?.Kendaraans[0]?.Id,
        PlatNomor: val?.Kendaraans[0]?.PlatNomor,
        RodaKendaraan: val?.Kendaraans[0]?.Roda
      }
    })

    const getCourier = async () => {
      const response = await courierUseCase.getAll({
        select: '&$select=Id,Handphone,Photo,Nama,IsActive,JenisKelamin,BirthDate,TempatLahir',
        expand: '&$expand=Kendaraans($filter=IsActive eq true;$select=Id,PlatNomor,Roda,IsActive)',
        filter: `MsAgenId eq ${store.getters['appActiveUser/getAgent']?.id} and IsActive eq true`
      })
      return mappingCourier(response.result)
    }

    const getDataAgent = async () => {
      const response = await getListAgent({
        custom: 'IsActive eq true',
        expand: '&$expand=TipeAgen($select=Tipe,Nama),Kota($select=Nama,Kode)',
        select: '&$select=Id,Alamat,KotaId,Nama,Kode,KodeResi',
        sorting: 'Kota/Nama asc'
      })
      if (!response.error) {
        store.dispatch(`${modules}/setListAgent`, response.result.map((data: any) => ({
          Alamat: data.Alamat,
          KotaId: data.KotaId,
          NamaKota: data.Kota.Nama,
          NamaAgent: data.Nama,
          Nama: (data.Kota) ? `${data.Kota.Nama} (${data.Nama})` : data.Nama,
          Id: data.Id
        })))
      }
    }

    const mappingMenu = (value: any) => value.map((menus: any) => ({
      name: menus.name,
      items: menus.items.map((second: { Icon: string; label: any; route: any; items: any[], Menu: any, Route: any }) => ({
        icon: (second.Icon) ? icons.value[second.Icon] : icons.value.menuOutlineEva,
        label: second.label ? second.label : second.Menu,
        route: second.route ? second.route : second.Route,
        items: second.items.map((third: any) => ({
          label: third.Menu,
          icon: third.Icon,
          route: third.Route,
          permission: {
            read: third.Read,
            update: third.Update,
            delete: third.Delete,
            write: third.Write
          }
        }))
      }))
    }))

    const getOpenTicketUser = async () => {
      const responseAllUser = await openTicketUseCase.getAllOtUser()
      if (!responseAllUser.error) {
        const account = responseAllUser.result[0]
        const dataOpenTicket = {
          email: `${account.Username}`,
          password: account.Password,
          host: `${account.Url}`
        }
        await store.dispatch('setAppOpenticketAccount', dataOpenTicket)
      }
    }
    const setStore = async (data: any) => {
      store.dispatch('setIsAuthenAuthenticated', true)
      store.dispatch('setDateAuthenAuthenticated', moment().format())
      if (data.result.Menu) {
        store.dispatch('setMenu', mappingMenu(data.result.Menu))
      }
      if (data.result.MenuKurir) {
        store.dispatch('setMenuKurir', mappingMenu(data.result.MenuKurir))
      }
      if (data.result.Route) {
        await store.dispatch('setRoute', data.result.Route)
      }
      store.dispatch('setAppActiveUser', data.result)
      const responseGlobalSetting = await getDataGlobalSetting(2)
      store.dispatch('setDataGlobalSetting', responseGlobalSetting)
      const responseNumberDigit = await getDataGlobalSetting(1)
      store.dispatch('setDataGlobeSettingNomorDigit', responseNumberDigit)
      const responseToleranceSchedule = await getDataGlobalSetting(6)
      await getDataAgent()
      await store.dispatch('setToleranceSchedule', responseToleranceSchedule)
      // const responseCourier = await getCourier()
      // await store.dispatch('kurir/setKurir', responseCourier)
      store.dispatch('setCustomView', data.result.CustomView)
      await getOpenTicketUser()
    }

    // console.log('login chalenge', route.query.login_challenge)
    const loginProcess = async () => {
      // if (isEmpty(email.value) || isEmpty(password.value)) {
      //   $toast.add({
      //     severity: 'error',
      //     detail: 'Username dan password harus diisi !!',
      //     group: 'bc',
      //     life: 3000
      //   })
      //   return
      // }
      loading.value = true
      const sendLoginChallenge = await authUseCase.sendingLoginChallenge(route.query.login_challenge, dataForm.email, dataForm.password)
      if (!sendLoginChallenge.error) {
        window.location.href = sendLoginChallenge.result.RedirectTo
        store.dispatch('setIsAuthenAuthenticated', true)
        store.dispatch('setDateAuthenAuthenticated', moment().format())
      } else {
        // console.log('aku eroor', sendLoginChallenge)
        loading.value = false
        $toast.add({
          severity: 'error',
          detail: sendLoginChallenge.message,
          group: 'bc',
          life: 3000
        })
      }
      // console.log('data hasil', data.message)
      // dataLogin.value.error = false
      // if (!dataLogin.value.error) {
      //   store.dispatch('setIsAuthenAuthenticated', true)
      //   store.dispatch('setDateAuthenAuthenticated', moment().format())
      //   store.dispatch('setMenu', mappingMenu(dataLogin.value.value.Menu))
      //   await store.dispatch('setRoute', dataLogin.value.value.Route)
      //   store.dispatch('setAppActiveUser', dataLogin.value.value)
      //   const responseGlobalSetting = await getDataGlobalSetting()
      //   store.dispatch('setDataGlobalSetting', responseGlobalSetting)
      //   // router.push({ name: 'master-user' })
      //   window.location.replace('/hjex')
      // }
      // const data = await loginFlow(email.value, password.value)
      // if (!data.error) {
      //   if (data.result.Agen) {
      //     if (Object.keys(data.result.Route).length <= 0) {
      //       loading.value = false
      //       $toast.add({
      //         severity: 'error',
      //         detail: 'Anda tidak memiliki hak akses. Harap hubungi administrator',
      //         group: 'bc',
      //         life: 3000
      //       })
      //     } else {
      //       await setStore(data)
      //       // router.push({ name: 'master-user' })
      //       // window.location.replace('/hjex')
      //       window.location.href = '/hjex'
      //       // router.replace('/hjex')
      //     }
      //   } else {
      //     await setStore(data)
      //     window.location.href = '/hjex/ansurance'
      //     // console.log('is asuransi on', data)
      //   }
      // } else {
      //   checkResponse(data)
      //   loading.value = false
      // }
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      loginProcess()
    }

    onMounted(() => {
      // console.log('masakkk', route.query)
      if (Object.keys(route.query).length === 0 && !isMobile()) {
        router.push({
          name: 'home'
        })
      }
      idb('auth')
    })

    return {
      result,
      email,
      password,
      loginProcess,
      app,
      checkResponse,
      getDataGlobalSetting,
      loading,
      version,
      baseUrl,
      dataForm,
      v$,
      submitData,
      submitted
    }
  }
}
